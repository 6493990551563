<template>
    <div class="main">
        <div class="live-list-box">
            <div v-for="(item,index) in list" :key="index" class="live-box-main">
                <div @click="toShare(item)" class="live-box-left">
                    <div>{{item.month}}.{{item.day}}</div>
                    <div>{{item.week}}</div>
                </div>
                <div @click="toShare(item)" class="live-box-right">
                    <div class="live-list-title live-line">{{item.theme}}</div>
                    <div class="live-list-time live-line">{{item.beginHour}}:{{item.beginMinutes}} - {{item.endHour}}:{{item.endMinutes}}</div>
                    <div class="live-list-name live-line">直播人：{{item.userName}}</div>
                </div>
                <div @click="toConfig(item)" class="config-box"><van-icon name="setting-o" /></div>
            </div>
        </div>
        <div class="bottom-box">
            <div class="button-box">
                <div @click="toAdd" class="bottom-left">
                    <span class="icon-box"><van-icon name="video" size="30" /></span>
                    <span>预约直播</span>
                </div>
                <div @click="toRedConfig" class="bottom-right">
                    <span class="icon-box"><van-icon name="setting" size="30"/></span>
                    <span>红包设置</span>
                </div>
            </div>
            <div @click="toHistory" class="more-list-button">查看直播记录 <van-icon name="arrow" size="10" /></div>
        </div>
    </div>
</template>

<script>
    import { getLiveList, getQxUserId } from '@/api/live'
    import Cookies from "js-cookie";
    import {Toast} from "vant";
    import VConsole from 'vconsole';
    export default {
        name: 'LiveList',
        data() {
            return {
                userId: '',
                list: ''
            }},
        created() {
            // Cookies.set('userId', 'Wangleiqiang')
            let userId=Cookies.get('userId')
            const userAdminIds = ['Wangleiqiang','Miyanchun']
            if(userAdminIds.indexOf(userId) !== -1){
                const vConsole = new VConsole();
                vConsole.setOption('log.maxLogNumber', 5000);
            }
            if(!userId){
                this.login()
            }else{
                this.userId=userId
                this.getAllLive()
            }
        },
        mounted:function(){
        },
        methods: {
            // 获取列表
            getAllLive(){
                let postData = {}
                postData.userid = this.userId
                getLiveList(postData).then(response => {
                    console.log(response)
                    this.list = response.data
                    for(let i = 0; i < response.data.length; i++){
                        response.data[i].reserveStart = response.data[i].reserveStart.replace(/-/g, '/')
                        response.data[i].week = this.getweekday(response.data[i].reserveStart)
                        const date = new Date(response.data[i].reserveStart);
                        response.data[i].month = date.getMonth() + 1
                        response.data[i].day = date.getDate()
                        response.data[i].beginHour = date.getHours().toString().padStart(2, "0")
                        response.data[i].beginMinutes = date.getMinutes().toString().padStart(2, "0")
                        // 获取结束时间
                        const endTime = new Date(response.data[i].reserveStart).getTime() + response.data[i].reserveLivingDuration * 1000
                        const endDate = new Date(endTime)
                        response.data[i].endHour = endDate.getHours().toString().padStart(2, "0")
                        response.data[i].endMinutes = endDate.getMinutes().toString().padStart(2, "0")
                    }
                })
            },
            // 计算指定时间是星期几
            getweekday(date){
                // date例如:'2022-03-05'
                var weekArray = ["周日","周一", "周二", "周三", "周四", "周五", "周六"]
                var week  = weekArray[new Date(date).getDay()]
                return week
            },
            // 跳转到新增
            toAdd(){
                this.$router.push({ name: 'AddLive', query: {}});
            },
            // 跳转到设置
            toConfig(item){
                let postData = {}
                postData.id = item.id
                postData.liveId = item.livingId
                this.$router.push({ name: 'User', query: postData});
            },
            // 跳转到分享页面
            toShare(item){
                let postData = {}
                postData.id = item.id
                postData.liveId = item.livingId
                this.$router.push({  path: 'shopShare', query: postData});
            },
            // 跳转到历史记录
            toHistory(){
                let postData = {}
                this.$router.push({  path: 'historyLive', query: postData});
            },
            // 跳转到红包设置
            toRedConfig(){
                let postData = {}
                this.$router.push({  path: 'redConfig', query: postData});
            },
            // 用户网页授权登录
            login(){
                let code= this.getCode("code");
                if(code){
                    let postData={}
                    postData.code=code
                    postData.agentId=1000092
                    getQxUserId(postData).then(response => {
                        if(response.code===200 || response.code===0){
                            Cookies.set('userId', response.data.userId)
                            this.userId=response.data.userId
                            this.getAllLive()
                        }else{
                            Toast.fail('获取userId失败');
                            return false
                        }
                    })
                }else{
                    let nowUrl = window.location.href;
                    let backurl = encodeURIComponent(nowUrl)
                    let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwf93ebffd5d31aa7a&redirect_uri=' + backurl + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
                    window.location.href=url
                }
            },
            // 截取code
            getCode(parameName) {
                /// 获取地址栏指定参数的值
                // 获取url中跟在问号后面的部分
                var parames = window.location.search;
                console.log(parames);
                // 检测参数是否存在
                if (parames.indexOf(parameName) > -1) {
                    var parameValue = "";
                    parameValue = parames.substring(
                        parames.indexOf(parameName),
                        parames.length
                    );
                    // 检测后面是否还有参数
                    if (parameValue.indexOf("&") > -1) {
                        // 去除后面多余的参数, 得到最终 parameName=parameValue 形式的值
                        parameValue = parameValue.substring(0, parameValue.indexOf("&"));
                        // 去掉参数名, 得到最终纯值字符串
                        parameValue = parameValue.replace(parameName + "=", "");
                        return parameValue;
                    }
                    parameValue = parameValue.replace(parameName + "=", "");
                    return parameValue;
                }
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 100%;
        min-height: 100vh;
        position: relative;
        background-color: #ffffff;
    }
    .live-list-box{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px;
    }
    .live-box-main{
        display: flex;
        background-color: #f6f6f6;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 15px;
    }
    .live-box-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        color: #a4a5a8;
        font-weight: bold;
        width: 60px;
        text-align: center;
    }
    .live-box-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100vw - 120px - 60px);
        padding: 0 10px;
    }
    .live-list-title{
        font-weight: bold;
        font-size: 16px;
        display:-webkit-box;/**对象作为伸缩盒子模型展示**/
        -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
        -webkit-line-clamp:1;/**显示的行数**/
        overflow:hidden;/**隐藏超出的内容**/
    }
    .live-line{
        height: 30px;
        line-height: 30px;
    }
    .live-list-time{
        font-size: 14px;
    }
    .live-list-name{
        font-size: 14px;
        color: #999a9a;
    }
    .bottom-box{
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        padding: 20px 0;
        background-color: #ffffff;
    }
    .button-box{
        display: flex;
        justify-content: space-between;
        width: calc(100vw - 40px);;
    }
    .icon-box{
        padding-bottom: 5px;
    }
    .bottom-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(50vw - 25px);
        height: 80px;
        background: linear-gradient(136deg, #49c407 0%, #49c407 100%);
        box-shadow: 3px 3px 8px 0px rgba(48, 51, 57, 0.2);
        border-radius: 2px;
        text-align: center;
        color: #ffffff;
    }
    .bottom-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(50vw - 25px);
        height: 80px;
        background: linear-gradient(136deg, #5DA3FA 0%, #2586FF 100%);
        box-shadow: 3px 3px 8px 0px rgba(48, 51, 57, 0.2);
        border-radius: 2px;
        text-align: center;
        color: #ffffff;
    }
    .more-list-button{
        font-size: 13px;
        margin: 20px 0 0 0;
        background-color: #f5f4f4;
        padding: 8px 15px;
        border-radius: 5px;
    }
    .config-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #5DA3FA;
        font-size: 30px;
    }
</style>
